import React from 'react'
import { Section } from '../components/section'

const Section2 = ({ theme = 'dark' }) => (
  <Section theme={theme} legacy>
    <div className="row">
      <div className="col col-md-6">
        <h1>Distansmonitorering</h1>
        <p>Distansmonitoreringsversionen finns i fyra olika konfigurationer:</p>
        <p>
          •	Hemmonitorering - här har individen möjlighet att genomföra mätningar
            på egen hand i hemmet under kort eller lång tid. Mätningarna 
            sker via sensorer och subjektiva skattningar via frågeformulär.  
        </p>
        <p>
          •	Samhällsrum Stationär - I denna konfiguration så kan personal 
            genomföra assisterad provtagning på individen vilket möjliggör
            mer avancerade sensorer. 
        </p>
        <p>
          •	Samhällsrum Portabel - Även här kan personal genomföra assisterad
            provtagning via en konfiguration som är anpassad för att
            vara mobil. Vårdgivare kan genomföra mätningar till exempel hemma
            hos en individ eller på ett äldreboende.  
        </p>
        <p>
          •	Samhällsrum Självservice – Individer genomför mätningar på egen
            hand i ett utrustat samhällsrum. Rummet används av många och kan
            placeras t.ex. i ett väntrum på en vårdcentral
            eller i glesbygdsområden.
        </p>
        <div className="link-wrapper">
          <a
            className="page-link"
            href="mailto:bjorn.sundqvist@nhiab.com?subject=Demo Hälsorummet"
          >
            Boka demo
          </a>
        </div>
      </div>
      <div className="col col-md-6">
        <h3 className="mt-sm-5">Hantera inskickad data från Healfy</h3>
        <p>
          När en användare har skickat in datan från hälsorummet blir
          den tillgänglig i Healfys vårdgivargränssnitt, snabbt och 
          enkelt via webben.
        </p>
      </div>
    </div>
  </Section>
)

export default Section2
