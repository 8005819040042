import React from 'react'
import { Section } from '../components/section'

const Section4 = ({ theme = 'dark' }) => (
  <Section theme={theme} legacy>
    <div className="row">
      <div className="col col-md-6">
        <h1>Utvecklingsregioner</h1>
        <p>
          Tack vare Healfys platsoberoende egenskaper finns en stor potential
          att utveckla Healfy för utvecklingsregioner som har bristfälliga,
          eller helt saknar, sjukvårdsresurser.
        </p>
        <p>
          Just nu utvärderar vi möjligheten att göra en första test i en
          utvecklingsregion tillsammans med några andra aktörer.
        </p>
        <div className="link-wrapper">
          <a
            className="page-link"
            href="mailto:bjorn.sundqvist@nhiab.com?subject=Demo Utvecklingsregioner"
          >
            Boka demo
          </a>
        </div>
      </div>
    </div>
  </Section>
)

export default Section4
