import React from 'react'
import { Section } from '../components/section'
import bankIdImg from '../images/bankid.png'
import personalProfileImg from '../images/profil.png'
import halsoenkatImg from '../images/enkat.png'
import matning from '../images/matning.png'
import skicka from '../images/skicka.png'

const Section5 = ({ theme = 'light' }) => (
  <Section theme={theme} legacy>
    <div id="hur-funkar-det" className="row align-items-center ">
      <div className="col col-md-6">
        <h4>DIGITAL HÄLSOKONTROLL</h4>
        <h1 className="heading-highligh">Såhär fungerar det</h1>
      </div>
    </div>
    <div className="row align-items-center  how-it-works__item">
      <div className="col-xs-12 col-md-4">
        <div className="circle">1</div>
        <p>Trygg inloggning med BankID eller RFID</p>
        <p className="small-text">
          För att komma åt sin personliga profil och kunna göra mätningar loggar
          användaren in med BankID eller RFID på en surfplatta som är
          installerad i det utrymme där mätningarna ska genomföras.
        </p>
      </div>
      <div className="col-xs-12 col-md-6 offset-md-2">
        <img src={bankIdImg} alt="bankid" />
      </div>
    </div>
    <div className="row align-items-center  how-it-works__item">
      <div className="col-xs-12 col-md-6 order-md-1 order-2 offset-md-1">
        <img src={personalProfileImg} alt="personal" />
      </div>
      <div className="col-xs-12 col-md-4 order-md-2 order-1">
        <div className="circle">2</div>
        <p>Se och uppdatera personlig profil</p>
        <p className="small-text">
          Alla användare har en personlig profil där det går att uppdatera data
          och se tidigare tagna mätningar.
        </p>
      </div>
    </div>
    <div className="row align-items-center  how-it-works__item">
      <div className="col-xs-12 col-md-4">
        <div className="circle">3</div>
        <p>Svara på hälsoenkät</p>
        <p className="small-text">
          För att hålla koll på fysiskt och psykiskt välmående fyller användaren
          själv i en hälsoenkät i med jämna mellanrum.
        </p>
      </div>
      <div className="col-xs-12 col-md-6 offset-md-2">
        <img src={halsoenkatImg} alt="hälsoenkät" />
      </div>
    </div>
    <div className="row align-items-center  how-it-works__item">
      <div className="col-xs-12 col-md-6 order-md-1 order-2 offset-md-1">
        <img src={matning} alt="personlig profil" />
      </div>
      <div className="col-xs-12 col-md-4 order-1 order-md-2">
        <div className="circle">4</div>
        <p>Genomför en mätning</p>
        <p className="small-text">
          När användaren är inloggad går det att genomföra själva mätningen.
          Exempel på mätning är blodtryck, vikt, blodsocker och blodvärde.
          Mätningarna görs med hjälp av utrustning på plats som är trådlöst
          kopplat till surfplattan.
        </p>
      </div>
    </div>
    <div className="row align-items-center  how-it-works__item">
      <div className="col-xs-12 col-md-4">
        <div className="circle">5</div>
        <p>Skicka in mätning</p>
        <p className="small-text">
          När mätningen är genomförd godkänns den av användaren och skickas in
          digitalt till vårdgivare.
        </p>
      </div>
      <div className="col-xs-12 col-md-6 offset-md-2">
        <img src={skicka} alt="ballonger" />
      </div>
    </div>
  </Section>
)

export default Section5
